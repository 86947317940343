

import * as amplitude from '@amplitude/analytics-browser';
import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const AMPLITUDE_API_KEY = 'b1149772d7f0deefc1e24dbf66c7a0bf'; // Замените на ваш ключ API

amplitude.init(AMPLITUDE_API_KEY);
// Импортируем компоненты страниц  
const Page = lazy(() => import('./pages/Page'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const OnbordingPage = lazy(() => import('./pages/OnbordingPage'));
const isAuthenticated = () => {
  const userElement = document.getElementById('user');
  if (userElement)
    return userElement.getAttribute('data-authenticated') === 'True';
  else
    return false;

};
const App = () => {
  return (
    <BrowserRouter>
      {/* fallback={<Loader isLoading isPage></Loader>} */}
      <Suspense >
        <Routes>
          <Route path="/" element={isAuthenticated() ? <Page /> : <LandingPage />} />
          <Route path="/:space_id/:page_id/" element={<Page />} />
          <Route path="/account/login/" element={< LoginPage />} />
          <Route path="/account/onbording/" element={< OnbordingPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode><App />
  </StrictMode>

);
